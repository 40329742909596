import FirstLayer from "../Components/AboutMe/FirstLayer"
import Secondlayer from "../Components/AboutMe/SecondLayer"

const About = () => {
    return (
        <div>
            <FirstLayer />
            <Secondlayer />
        </div>
    )
}

export default About