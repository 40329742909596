import FirstLayer from "../Components/Contact/FirstLayer"

const Contact = () => {
    return (
        <section>
            <FirstLayer />
        </section>
    )
}

export default Contact;